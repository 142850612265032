(function(){

  // Please read js/src/trackui.js for the API and code documentation.
  window.TrackUI.record({
    debug: true,

    // Example: track all clicks and poll mouse movements at 50 ms
    regularEvents: "click",
    pollingEvents: "mousemove",
    pollingMs: 50,

    // Example: pure event polling at 500 ms
    //regularEvents: "",
    //pollingEvents: "*",
    //pollingMs: 500,

    postServer: "https://8ba3pm0qja.execute-api.eu-west-1.amazonaws.com/Lambda-Firehose-Producer"
  });

})();